import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";

import { Helmet } from "react-helmet"
import * as icons from "@heroicons/react/outline";


const Note = ({ note }) => (
  <div class="flex flex-row text-center items-center group">
    <icons.StarIcon class="w-5 h-5 mr-1 group-hover:text-pink-300" />
    <a class="text-left group-hover:underline decoration-pink-300" href={note.url}>{note.title}</a>
  </div>
)

const NoteList = ({ notes }) => {
  console.log("notes:", notes)
  if (notes) {
    let Notes = notes.map((n) =>
      <Note note={n} />
    )
    console.log("notesss:", Notes)

    return (
      <div class="flex flex-row font-semibold">
        {Notes}
      </div>
    )
  } else {
    return (<div></div>)
  }

}

const PubOrTalk = ({ title, authors, venue, year, description, url, file, slug, type, notes }) => {
  console.log("FILE:", file)
  const ItemIcon = ({ type }) => {
    if (type == "paper") {
      return (<icons.AcademicCapIcon />)
    } else if (type == "talk") {
      return (<icons.ChatIcon />)
    } else if (type == "popular") {
      return (<icons.NewspaperIcon />)
    } else {
      return (<icons.SparklesIcon />)
    }
  }
  const itemUrl = () => {
    console.log("file?", file)
    return file ? file.publicURL : url
  }
  return (
    <li class="mb-10 ml-6 group">
      < div class="flex flex-row absolute justify-start items-center -left-20" >
        <div class="flex flex-col justify-start w-12 pl-1">
          <span class="mb-1 text-xs font-normal text-gray-400">{venue}</span>
          <span class="mb-1 text-s font-normal text-gray-400">{year}</span>
        </div>
        <span class="justify-center items-center ml-4 w-8 h-8 rounded-full bg-gray-100 ring-8 ring-white">
          <ItemIcon type={type} />
        </span>
      </div >

      <div className="flex flex-row flex-wrap md:flex-nowrap w-full rounded-lg p-2">
        <div className="flex flex-col bg-white">
          <a href={itemUrl()} className="text-xl font-bold group-hover:underline">{title}</a>
          <p class="text-lg">{authors}</p>
          <p class=" text-s italic">{description}</p>
          <div class="flex flex-row">
            <NoteList notes={notes} />
          </div>
        </div>
      </div>

    </li >
  );
};

export const NewsItemsList = ({ items }) => {
  console.log("items:", items)

  const [selectedType, setSelectedType] = useState('All Items')
  const [filteredItems, setFilteredItems] = useState(items)

  const typeNameMap = {
    'Refereed Papers': "paper",
    'Talks': 'talk',
    'Preprints & Arxiv': 'preprint',
    'Popular': 'popular',
  }

  useEffect(() => {
    console.log("updating filtered items:", selectedType, selectedType == 'All Items', typeNameMap[selectedType])
    if (selectedType == 'All Items') {
      setFilteredItems(items)
    } else {

      setFilteredItems(
        items.filter((n) => {
          return n.frontmatter.type == typeNameMap[selectedType]
        })
      )
    }
  }, [selectedType])


  const TypePill = ({ type }) => {
    const isSelected = type == selectedType
    return (
      <li class={`rounded-full border-black border-2 px-2 py-1 m-1 text-xs font-semibold ${isSelected ? 'bg-gray-500 text-white' : ''}`}>
        <a onClick={() => setSelectedType(type)}>{type}</a>
      </li>
    )
  }


  return (

    <div className="flex flex-col w-full px-2 overflow-x-clip justify-center content-center md:w-2/3 ">
      <h1 className="text-3xl font-bold self-start mb-5">News</h1>
      <ul class="flex flex-row flex-wrap">
        <TypePill type="All Items" />
        <TypePill type="Refereed Papers" />
        <TypePill type="Talks" />
        <TypePill type="Preprints & Arxiv" />
        <TypePill type="Popular" />
      </ul>

      <div class="pl-20">
        <ol class="relative border-l border-gray-200 dark:border-gray-700">
          {filteredItems.map((o) => (
            <PubOrTalk
              title={o.frontmatter.title}
              authors={o.frontmatter.authors}
              year={o.frontmatter.date}
              venue={o.frontmatter.venue}
              description={o.frontmatter.description}
              url={o.frontmatter.url}
              file={o.frontmatter.file}
              notes={o.frontmatter.notes}
              slug={o.slug}
              type={o.frontmatter.type}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

const PostsPage = ({ data }) => {
  console.log("data in postspage:", data)
  return (
    <main className="flex flex-row flex-wrap w-full h-full bg-white text-black justify-center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dana Calacci - Pubs and Talks</title>
      </Helmet>
      <Header large={false} />
      <NewsItemsList items={data.allMdx.nodes} />
    </main>
  )
}

export const query = graphql`
  query PublicationQuery {
allMdx(
      filter: { frontmatter: { templateKey: { in: ["pubs-and-talks"] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        body
        slug
        frontmatter {
          title
          authors
          venue
          url
          type
          description
          date(formatString: "YYYY")
          file {
            publicURL
            }
          notes {
            title
            url
          }
        }
      }
    }
  }
`;

export default PostsPage;
